<template>
  <ul class="app-nav desktop">
    <li v-for="(item, index) of mainMenu" :key="index" class="app-nav__item">
      <slot v-if="item.search" :item="item" />

      <template v-else-if="!item.private || $store.getters['user/isLoggedIn']">
        <button
          v-if="
            item.modalSecondaryCondition &&
              $store.getters[item.modalSecondaryCondition]
          "
          @click="$store.commit('toggleGuestModalSecondary', true)"
          class="app-nav__link"
        >
          {{ item.name }}
        </button>
        <router-link
          v-else
          :to="item.to"
          class="app-nav__link"
          :class="{ disabled: searchActive }"
          @mouseenter.native="setRouterLinkClicked(true)"
          @mouseleave.native="setRouterLinkClicked(false)"
          @click.native="$emit('searchState', false)"
        >
          <app-nav-account-link v-if="item.account" :sticky="sticky" />
          <span v-else>{{ item.name }}</span>
        </router-link>
        <ul v-if="item.children" class="app-nav__submenu">
          <li
            v-for="(subitem, subindex) of item.children"
            :key="subindex"
            class="app-nav__submenu-item"
          >
            <router-link
              :to="subitem.to"
              class="app-nav__submenu-link app-nav__link"
            >
              {{ subitem.name }}
            </router-link>
          </li>
        </ul>
      </template>

      <button
        v-else-if="item.modalSecondaryCondition"
        @click="$store.commit('toggleGuestModalSecondary', true)"
        class="app-nav__link"
      >
        {{ item.name }}
      </button>
      <button
        v-else
        @click="$store.commit('openGuestModal')"
        class="app-nav__link"
      >
        <app-nav-account-link v-if="item.account" :sticky="sticky" />
        <span v-else>{{ item.name }}</span>
      </button>
    </li>
  </ul>
</template>

<script>
import AppNavAccountLink from './AppNavAccountLink';
import { mainMenu } from '@/router/mainMenu';
import { mapMutations } from 'vuex';

export default {
  components: {
    AppNavAccountLink,
  },
  props: {
    sticky: Boolean,
    searchActive: Boolean,
  },
  computed: {
    mainMenu() {
      return mainMenu(
        this.$store.getters['user/otherActs'],
        this.$store.getters['user/ue']
      );
    },
  },
  methods: {
    ...mapMutations(['setRouterLinkClicked']),
  },
};
</script>

<style lang="scss" scoped>
.app-nav {
  display: flex;
  align-items: center;
}
.app-nav__item {
  position: relative;
  margin-right: 3rem;
}
.app-nav__link {
  display: block;
  font-size: 1.28rem;
  font-weight: bold;
  line-height: 1.3;
  white-space: nowrap;
  padding-bottom: 0.3rem;
  border-bottom: 2px solid transparent;
  color: $gray-3;
  transition: color 0.15s, border-bottom-color 0.4s;

  &::before {
    content: '';
    position: absolute;
    left: -2em;
    right: -2em;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  &:hover {
    color: $primary;

    &::before {
      pointer-events: auto;
    }

    & + .app-nav__submenu {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(-50%) translateY(0);
    }
  }
  &.router-link-active {
    color: $primary;
    border-bottom-color: $primary;
  }
  &.disabled {
    // pointer-events: none;
    color: $gray-3;
    border-bottom-color: transparent;
    &:hover {
      color: $primary;
    }
  }
}

.app-nav__submenu {
  z-index: 100;
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  padding-top: 1rem;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%) translateY(-0.5rem);
  transition: opacity 0.2s, transform 0.2s $easeOutCubic;

  .app-nav__link::before {
    display: none;
  }

  &:hover {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-50%) translateY(0);
  }
}
.app-nav__submenu--closed {
  display: none;
}
.app-nav__submenu-item {
  margin: 0 1rem;
}
.app-nav__submenu-link {
  font-size: 1.14rem;
}
</style>
