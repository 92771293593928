<template>
  <div class="app">
    <header class="app__header mb-8" :class="{ 'mb-tablet-24': !comboSearch }">
      <portal to="sticky-nav">
        <div
          class="app__nav-container"
          :class="{ 'app__nav-container--sticky': stickyHeader }"
        >
          <app-guest-info v-if="!$store.getters['user/isLoggedIn']" />
          <app-survey-info
            v-else-if="surveyInfo"
            @close="surveyInfoClosed = true"
          />
          <nav
            class="app__nav"
            :class="{
              infobar: !$store.getters['user/isLoggedIn'] || surveyInfo,
            }"
            ref="nav"
          >
            <app-nav
              :sticky="stickyHeader"
              :searchActive="searchActive"
              @searchState="v => (searchActive = v)"
              #default="{item}"
            >
              <div ref="navSearch">
                <button
                  @click="openSearch('')"
                  class="app-nav__link"
                  :class="{
                    'router-link-active': searchActive || searchPage,
                  }"
                >
                  {{ item.name }}
                </button>
                <ul
                  v-if="item.children"
                  class="app-nav__submenu"
                  :class="{
                    'app-nav__submenu--closed': searchActive || comboSearch,
                  }"
                >
                  <li
                    v-for="(subitem, subindex) of item.children"
                    :key="subindex"
                    class="app-nav__submenu-item"
                  >
                    <button
                      @click="openSearch(subitem.relation)"
                      class="app-nav__submenu-link app-nav__link"
                    >
                      {{ subitem.name }}
                    </button>
                  </li>
                </ul>
              </div>
            </app-nav>

            <router-link to="/" class="app__logo">
              <span class="visuallyhidden"
                >vigilex monitoringregulacyjny.pl</span
              >
              <base-logo-vertical class="app__logo-image" />
            </router-link>

            <div class="app__nav-right mobile-tablet">
              <button
                ref="mobileSearch"
                @click="openSearch('')"
                class="app__nav-search color-primary"
              >
                <base-icon-search />
              </button>

              <navigation-menu key="app-menu" />
            </div>

            <search-bar
              v-if="$route.name.includes('search')"
              :active="searchActive"
              :relation="searchRelation"
              @search="closeSearch"
              class="app-search"
            />
          </nav>
        </div>
      </portal>
    </header>

    <main class="app__content mb-16">
      <router-view />
    </main>

    <page-footer class="app__footer" />
  </div>
</template>

<script>
import PageFooter from '@/components/PageFooter/PageFooter';
import NavigationMenu from '@/components/Navigation/NavigationMenu';
import SearchBar from '../Search/components/SearchBar';
import AppNav from './components/AppNav';
import AppGuestInfo from './components/AppGuestInfo';
import AppSurveyInfo from './components/AppSurveyInfo';

export default {
  components: {
    PageFooter,
    NavigationMenu,
    SearchBar,
    AppNav,
    AppGuestInfo,
    AppSurveyInfo,
  },
  data() {
    return {
      stickyHeader: false,
      searchActive: Boolean(
        this.$route.query.combo || this.$route.query.advanced
      ),
      searchRelation: '',
      surveyInfoClosed: false,
      idleTime: 30 * 60 * 1000,
      idleTimer: null,
    };
  },
  computed: {
    surveyInfo() {
      return (
        this.$store.getters['user/hasUnfinishedSurvey'] &&
        !this.surveyInfoClosed
      );
    },
    searchPage() {
      return this.$route.name.includes('search');
    },
    comboSearch() {
      return this.searchPage && !this.$route.query.advanced;
    },
  },
  watch: {
    searchActive: {
      immediate: true,
      handler: function(v) {
        this.$store.state.isSearchActive = v;
      },
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    this.onScroll();
  },
  mounted() {
    this.addIdleListeners();
    this.resetIdleTimer();
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    resetIdleTimer() {
      clearTimeout(this.idleTimer);
      this.idleTimer = setTimeout(() => {
        window.location.reload();
      }, this.idleTime);
    },
    addIdleListeners() {
      const events = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart'];
      events.forEach(event => {
        document.addEventListener(event, this.resetIdleTimer, false);
      });
    },
    onScroll() {
      if (this.$store.state.scrollBlock) {
        return;
      }
      let threshold = this.$util.mq('desktop') ? 53.8 : 26.9;
      if (!this.$store.getters['user/isLoggedIn'] || this.surveyInfo) {
        threshold = 0;
      }
      this.stickyHeader = window.scrollY > threshold;
    },
    openSearch(searchRelation) {
      if (!this.$store.getters['user/isLoggedIn']) {
        this.$store.commit('openGuestModal');
        return;
      }
      if (this.comboSearch) {
        return;
      }

      window.scrollTo(0, 0);
      this.searchRelation = searchRelation;
      this.searchActive = true;

      if (['account'].includes(this.$route.name)) {
        this.$router.push({ name: 'legislation' });
      } else if (
        this.$route.name.includes('my-monitoring-') ||
        this.$route.name.includes('-item')
      ) {
        this.$router.push({ name: this.$route.meta.relation });
      }
    },
    closeSearch(event) {
      const isNavEvent =
        event &&
        this.$refs.navSearch &&
        this.$refs.navSearch.contains(event.target);
      const isMobileEvent =
        event &&
        this.$refs.mobileSearch &&
        this.$refs.mobileSearch.contains(event.target);
      if (!isNavEvent && !isMobileEvent) {
        this.searchActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './App';
</style>
