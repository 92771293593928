var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[_c('header',{staticClass:"app__header mb-8",class:{ 'mb-tablet-24': !_vm.comboSearch }},[_c('portal',{attrs:{"to":"sticky-nav"}},[_c('div',{staticClass:"app__nav-container",class:{ 'app__nav-container--sticky': _vm.stickyHeader }},[(!_vm.$store.getters['user/isLoggedIn'])?_c('app-guest-info'):(_vm.surveyInfo)?_c('app-survey-info',{on:{"close":function($event){_vm.surveyInfoClosed = true}}}):_vm._e(),_vm._v(" "),_c('nav',{ref:"nav",staticClass:"app__nav",class:{
            infobar: !_vm.$store.getters['user/isLoggedIn'] || _vm.surveyInfo,
          }},[_c('app-nav',{attrs:{"sticky":_vm.stickyHeader,"searchActive":_vm.searchActive},on:{"searchState":v => (_vm.searchActive = v)},scopedSlots:_vm._u([{key:"default",fn:function({item}){return [_c('div',{ref:"navSearch"},[_c('button',{staticClass:"app-nav__link",class:{
                  'router-link-active': _vm.searchActive || _vm.searchPage,
                },on:{"click":function($event){return _vm.openSearch('')}}},[_vm._v("\n                "+_vm._s(item.name)+"\n              ")]),_vm._v(" "),(item.children)?_c('ul',{staticClass:"app-nav__submenu",class:{
                  'app-nav__submenu--closed': _vm.searchActive || _vm.comboSearch,
                }},_vm._l((item.children),function(subitem,subindex){return _c('li',{key:subindex,staticClass:"app-nav__submenu-item"},[_c('button',{staticClass:"app-nav__submenu-link app-nav__link",on:{"click":function($event){return _vm.openSearch(subitem.relation)}}},[_vm._v("\n                    "+_vm._s(subitem.name)+"\n                  ")])])}),0):_vm._e()])]}}])}),_vm._v(" "),_c('router-link',{staticClass:"app__logo",attrs:{"to":"/"}},[_c('span',{staticClass:"visuallyhidden"},[_vm._v("vigilex monitoringregulacyjny.pl")]),_vm._v(" "),_c('base-logo-vertical',{staticClass:"app__logo-image"})],1),_vm._v(" "),_c('div',{staticClass:"app__nav-right mobile-tablet"},[_c('button',{ref:"mobileSearch",staticClass:"app__nav-search color-primary",on:{"click":function($event){return _vm.openSearch('')}}},[_c('base-icon-search')],1),_vm._v(" "),_c('navigation-menu',{key:"app-menu"})],1),_vm._v(" "),(_vm.$route.name.includes('search'))?_c('search-bar',{staticClass:"app-search",attrs:{"active":_vm.searchActive,"relation":_vm.searchRelation},on:{"search":_vm.closeSearch}}):_vm._e()],1)],1)])],1),_vm._v(" "),_c('main',{staticClass:"app__content mb-16"},[_c('router-view')],1),_vm._v(" "),_c('page-footer',{staticClass:"app__footer"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }